import appLogo from '../assets/img/intro7/appLogo.png'
import appStore from '../assets/img/intro7/appStore.png'
import playStore from '../assets/img/intro7/playStore.png'
import coLogo from '../assets/img/footer/coLogo.svg'

import intro1_background from '../assets/img/intro1/background.png'
import intro1_logo from '../assets/img/intro1/logo.png'

import intro2_background from '../assets/img/intro2/background.png'
import card from '../assets/img/intro2/card.png'
import intro2_background_mobile from '../assets/img/intro2/background_mobile.png'

import book from '../assets/img/intro3/book.png'
import book_mobile from '../assets/img/intro3/book_mobile.png'

import phone4_1 from '../assets/img/intro4/phone4-1.png'
import phone4_2 from '../assets/img/intro4/phone4-2.png'

import phone5_1 from '../assets/img/intro5/phone5-1.png'
import phone5_2 from '../assets/img/intro5/phone5-2.png'

const images = {
  intro1: {
    background: intro1_background,
    logo: intro1_logo
  },
  intro2: {
    background: intro2_background,
    card: card,
    background_m: intro2_background_mobile,
  },
  intro3: {
    book: book,
    book_mobile: book_mobile,
  },
  intro4: {
    phone1: phone4_1,
    phone2: phone4_2,
  },
  intro5: {
    phone1: phone5_1,
    phone2: phone5_2,
  },
  intro7: {
    appLogo: appLogo,
    appStore: appStore,
    playStore: playStore,
  },
  footer: {
    coLogo: coLogo,
  }
};

export default images;