import React from 'react'
import styled from 'styled-components'
import images from '../common/images'
import { goHere } from '../common/util'

function Footer() {
  return (
    <>
    <FooterWrapper>
        <Container>
            <LogoBox>
                <img src={images.footer.coLogo} alt='WEDID!' />
            </LogoBox>
            <InfoBox>
                <LeftInfo>
                    <SpanBut onClick={()=>{goHere('https://main.projectwith.io/policy?type=privacy')}}>
                        개인정보 취급방침
                    </SpanBut>
                    <Line>|</Line>
                    <SpanBut onClick={()=>{goHere('https://main.projectwith.io/policy?type=service')}}>
                        서비스 이용약관
                    </SpanBut>
                    <div>
                        2022 All rights reserved by ProjectWITH
                    </div>
                </LeftInfo>
                <RightInfo>
                    <span>이메일</span>
                    <SpanBut onClick={()=>{goHere('mailto:contact@projectwith.io')}}>
                        contact@projectwith.io
                    </SpanBut><br/>
                    <span>홈페이지</span>
                    <SpanBut onClick={()=>{goHere('https://www.projectwith.io/')}}>
                        https://www.projectwith.io/
                    </SpanBut>
                </RightInfo>
            </InfoBox>
        </Container>
    </FooterWrapper>
    </>
  )
}

export default Footer

const FooterWrapper = styled.footer`
width: 100%;
height: 459px;
bottom: 0;
background-color: #262626;
padding-top: 108px;
display: flex;

    @media (max-width: 1024px){
        height: 300px;
        flex-direction: column;
    }
    @media (max-width: 600px){
        height: 182px;
        padding-top: 37px;
    }
`;

const Container = styled.div`
display: flex;
flex-direction: row;
margin-left: auto;
margin-right: auto;
    @media (max-width: 1024px){
        flex-direction: column;
    }
`;

const LogoBox = styled.div`
float: left;

    @media (max-width: 1024px){
        img{
            width: 58.79px;
        }
    }      
`;

const InfoBox = styled.div`
margin-left: 19.5vw;
font-size: 14px;
font-weight: 500;
color: #A5A5A5;
float: left;
    a {
        color: #A5A5A5;
        text-decoration: none;
    }
    @media (max-width: 1024px) {
        margin-left: 0;
    }

    @media (max-width: 600px) {
        font-size: 7.8px;
    }
`;

const LeftInfo = styled.div`
float: left;
    @media (max-width: 600px){
        margin-left: 0; 
    }
`;

const Line = styled.span`
margin: 0 10px;
cursor: default !important;
`

const RightInfo = styled.div`
float: left;
margin-left: 5vw;
    :nth-child(2) {
        margin-left: 15px;
    }
    :last-child {
        margin-left: 13px;
    }

    @media (max-width: 600px){
        margin-left: 25px; 
    }
`;

const SpanBut = styled.span`
cursor: pointer;
`



