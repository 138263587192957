import React from 'react'
import styled from 'styled-components';
import introStyleAtom from '../../common/introStyleAtom';
import images from '../../common/images';

const Intro4 = () => {
    // const {Container, Flex, Flex2, TagBox} = introStyleAtom();
    const {TopTitle} = introStyleAtom();


  return (
    <Wrapper> 
          <Flex>
            <Left>
              <TopTitle>WEDID 주요 기능</TopTitle>
                <InfoBox>
                    <MidTitle>
                      대회 신청
                    </MidTitle>
                    <Line/>
                    <BotTitle>
                      대회 신청, 선수 검인, 출전 선수 명단까지 앱을 통해 원큐에 진행할 수 있는 장점!
                    </BotTitle>
                </InfoBox>
                <SImg src={images.intro4.phone1} alt=''/>
            </Left>
          </Flex>

          <Flex2>
            <Right>
                <InfoBox>
                    <MidTitle>
                      팀 관리
                    </MidTitle>
                    <Line/>
                    <BotTitleRight>
                      팀 매너 점수, 경기 전적 등 상세 정보를 확인할 수 있으며 멤버 관리 및 참여 대회, 경기를 쉽게 확인 가능!
                    </BotTitleRight>
                </InfoBox>
                <SImg src={images.intro4.phone2} alt=''/>
            </Right>
          </Flex2> 
    </Wrapper>
  )
}

export default Intro4

const Wrapper = styled.div`
width: 100%;
height: 775px; 
display: flex;
@media (max-width: 800px) { 
  flex-direction: column;
  height: 100%;
}
`

const Flex = styled.div`
flex: 1; 
background-color: #498aff;
display: flex;
justify-content: flex-end;
@media (max-width: 800px) { 
  justify-content: center;
}
`

const Flex2 = styled.div`
flex: 1;
display: flex;
background-color: #3A77E7;
@media (max-width: 800px) { 
  justify-content: center;
}
`

const Container = styled.div`
width: 790px;
height: 100%;
overflow: hidden;
`

const Left = styled(Container)`
padding-right: 60px;
padding-left: 70px;
@media (max-width: 800px) { 
  width: 375px;
  padding-left: 25px;
  padding-right: 25px;
}
`

const Right = styled(Container)`
padding-right: 70px;
padding-left: 60px;
padding-top: 69px;
text-align: right;
align-items: end;
justify-content: end;
@media (max-width: 800px) { 
  width: 375px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 14px;
  text-align: left;
  align-items: center;
  justify-content: center;
}
`

const InfoBox = styled.div`
margin-top: 57px;
margin-bottom: 67px;
@media (max-width: 800px) { 
  margin-top: 16px;
  margin-bottom: 25px;
  margin-left: 12px;
}
`

const MidTitle = styled.div`
color: #FFF; 
font-size: 55px; 
font-weight: 800;
line-height: 82.459px; 
@media (max-width: 800px) { 
  font-size: 28px;
  line-height: 28px;
}
`

const Line = styled.div`
width: 100%;
border-top: 1px solid #ffffff;
color: white;
margin-top: 24px;
margin-bottom: 34px;
@media (max-width: 800px) { 
  width: calc(100% - 24px);
  margin: 8px 0;
}
`

const BotTitle = styled.div`
width: 412px;
color: #FFF; 
font-size: 24px; 
font-weight: 500;
line-height: 34px;  
@media (max-width: 800px) { 
  width: 207px;
  font-size: 12px;
  line-height: 17px;
}
`

const BotTitleRight = styled.div`
width: 566px;
color: #FFF; 
font-size: 24px; 
font-weight: 500;
line-height: 34px;  
margin-left: auto;
@media (max-width: 800px) { 
  font-size: 12px;
  line-height: 17px;
  width: 283px;
  margin-left: 0;
}
`

const SImg = styled.img`
width: 633px;
@media (max-width: 800px) { 
  min-width: 290px;
  width: 100%;
  padding: 0 12px;
}
`
