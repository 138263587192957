import React from 'react'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Intro1 from '../components/home/Intro1'
import Intro7 from '../components/home/Intro7'
import Intro2 from '../components/home/Intro2'
import Intro3 from '../components/home/Intro3'
import Intro4 from '../components/home/Intro4'
import Intro5 from '../components/home/Intro5'
import Intro6 from '../components/home/Intro6'

const Home = () => {
  return (
    <Wrapper>
        <Intro1/>
        <Intro2/>
        <Intro3/>
        <Intro4/>
        <Intro5/>
        <Intro6/>
        <Intro7/>
        <Footer/>
    </Wrapper>
  )
}

export default Home

const Wrapper = styled.div`
min-width: 1780px;
@media (max-width: 800px) {
  min-width: 375px;
}
`