import React from 'react'
import styled from 'styled-components';
import introStyleAtom from '../../common/introStyleAtom';
import images from '../../common/images';
import { goHere } from '../../common/util';

const Intro3 = () => {
    const {TagBox, TopTitle} = introStyleAtom();
    const 가이드북관리자 = 'https://firebasestorage.googleapis.com/v0/b/wedid-350512.appspot.com/o/admin%2FguideBook%2FWEDID%20%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3%E1%84%87%E1%85%AE%E1%86%A8%20%E1%84%80%E1%85%AA%E1%86%AB%E1%84%85%E1%85%B5%E1%84%8C%E1%85%A1%E1%84%8B%E1%85%AD%E1%86%BC.pdf?alt=media&token=d3946e1e-ee30-4af7-b79a-946abf0ee882';
    const 가이드북유저 = 'https://firebasestorage.googleapis.com/v0/b/wedid-350512.appspot.com/o/admin%2FguideBook%2FWEDID%20%E1%84%80%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3%E1%84%87%E1%85%AE%E1%86%A8%20%E1%84%8B%E1%85%B2%E1%84%8C%E1%85%A5%E1%84%8B%E1%85%AD%E1%86%BC.pdf?alt=media&token=42022843-2d7f-4444-86bd-fa4864386323';

  return (
    <Wrapper>
        <DesktopContainer>
            <Flex>
                <TopTitle>WEDID 사용 안내서</TopTitle>
                <MainImg src={images.intro3.book} alt=''/>
                <TagBox style={{backgroundColor:'#498AFF', color:'white'}}>03</TagBox>
            </Flex>
            <Flex2>
                <InfoBox>
                    <MidTitle>WEDID APP을 <br/>제대로 사용하고 싶다면?</MidTitle>
                    <Line/>
                    <BotTitle>
                        사용 안내서는 관리자용과 유저용으로 나눠져 있습니다. <br/>
                        원하는 사용 안내서(PDF)를 다운 받아서 정독해 주세요!
                    </BotTitle>
                </InfoBox>
                <ButtonBox>
                    <AdminButt onClick={()=>{goHere(가이드북관리자)}}>관리자용 다운 받기</AdminButt>
                    <UserButt onClick={()=>{goHere(가이드북유저)}}>유저용 다운 받기</UserButt>
                </ButtonBox>
            </Flex2>
        </DesktopContainer>

        <MobileContainer>
            <TopTitle>WEDID 사용 안내서</TopTitle>
            <Flex>
                <InfoBox>
                    <MidTitle>WEDID APP을 <br/>제대로 사용하고 싶다면?</MidTitle>
                    <Line/>
                    <BotTitle>
                        사용 안내서는 관리자용과 유저용으로 나눠져 있습니다. <br/>
                        원하는 사용 안내서(PDF)를 다운 받아서 정독해 주세요!
                    </BotTitle>
                </InfoBox>
                <ImgBox>
                    <MainImg src={images.intro3.book_mobile} alt=''/>
                </ImgBox>
                <TagBox style={{backgroundColor:'#498AFF', color:'white'}}>03</TagBox>
                <ButtonBox>
                    <AdminButt onClick={()=>{goHere(가이드북관리자)}}>관리자용 다운 받기</AdminButt>
                    <UserButt onClick={()=>{goHere(가이드북유저)}}>유저용 다운 받기</UserButt>
                </ButtonBox>
            </Flex>


        </MobileContainer>
    </Wrapper>
  )
}

export default Intro3

const Wrapper =styled.div`
width: 100%;
height: 828px;
background-color: #FF8667;
position: relative;
display: flex;
justify-content: center;
background-size: cover;
background-position: right;
@media (max-width: 800px) { 
  height: 500px;
}
`

const DesktopContainer = styled.div`
display: flex;
width: 1580px;
padding-left: 70px;
padding-right: 70px;
height: 100%;
@media (max-width: 800px) { 
  display: none;
}
`

const MobileContainer = styled.div`
width: 375px;
padding-left: 25px;
padding-right: 25px;
@media (min-width: 800px) { 
  display: none;
}

`

const InfoBox = styled.div`
margin-top: 158px;
margin-bottom: 56px;
@media (max-width: 800px) {  
    margin-top: 16px;
    margin-bottom: 10px;
    margin-left: 12px;
}
`

const MidTitle = styled.div`
width: 723px;
height: 192px;
color: #FFF;
text-align: right; 
font-size: 70px; 
font-weight: 800;
line-height: 96px; 
letter-spacing: -1.4px;
@media (max-width: 800px) {  
    width: 248px;
    height: 60px;
    color: #FFF; 
    font-size: 24px; 
    line-height: 30px;  
    letter-spacing: -0.48px;
    text-align: left;
}
`

const BotTitle = styled.div`
width: 723px;
height: 68px;
color: #FFF;
text-align: right; 
font-size: 24px; 
font-weight: 500;
line-height: 34px;  
@media (max-width: 800px) {   
    width: 284px;
    height: 34px;
    font-size: 12px; 
    line-height: 17px;  
    text-align: left; 
}
`

const Line = styled.div`
width: 100%;
border-top: 1px solid #ffffff;
color: white;
margin-top: 36px;
margin-bottom: 36px;
@media (max-width: 800px) {
    width: calc(100% - 24px);
    margin-top: 8px;
    margin-bottom: 8px;
}
`

const ButtonBox = styled.div`
width: 723px;
display: flex;
gap: 20px;
justify-content: end;
@media (max-width: 800px) {
    justify-content: center;
    gap: 34px;
    width: 100%;
}
`

const Butt = styled.button`
width: 253px;
height: 67px;
border-radius: 33px;
background: #FFF;
border: none; 
font-size: 24px; 
font-weight: 500;
line-height: 42px;
@media (max-width: 800px) {  
    width: 124px;
    height: 33px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 16px;
}
`

const AdminButt = styled(Butt)`
color: #FC5B3D;
`

const UserButt = styled(Butt)`
color: #498AFF;
`

const MainImg = styled.img`
width: 650px;
@media (max-width: 800px) {  
    width: 300px;
}
`

const Flex = styled.div`
flex: 1;
`

const Flex2 = styled.div`
flex: 1;
`

const ImgBox = styled.div`
text-align: center;
`